import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const ErrorWrapper = styled.div`

  height: 100%;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
`

export default function Error({error} : {error: string}) {
    return (
        <ErrorWrapper title="0">
            <FontAwesomeIcon icon={faCircleXmark} fontSize={50} width={50} height={50}/>
            {error}
        </ErrorWrapper>
    )
}