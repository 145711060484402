import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AnimatePresence, motion, useAnimation} from "framer-motion";
import Card from "../../components/card/Card";
import Calendar from "../../components/calendar/Calendar";
import StatisticsService from "../../service/StatisticsService";
import WorkoutInfo from "../../components/workout_info/WorkoutInfo";
import {WorkoutDataDTO} from "../../service/dto/MonthStatisticsDTO";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import ErrorDescriptionDTO from "../../service/dto/ErrorDescriptionDTO";
import GenderDTO from "../../service/dto/GenderDTO";
import AppRoutes from "../../routes";

const MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

const CalendarFrame = styled(motion.div)`
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  .swipe {
    height: 100%;
    width: 100%;
  }
`

const ArrowLeft = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 100%;
  color: #0edba4;
  cursor: pointer;
`

const ArrowRight = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 100%;
  color: #0edba4;
  cursor: pointer;
`



function CalendarScreen({service}: {service: StatisticsService}) {

    const navigate = useNavigate();
    const { search } = useLocation();
    const animationControl = useAnimation();

    const [selectedWorkout, setSelectedWorkout] = useState<{gender: GenderDTO, workout: WorkoutDataDTO}>(null);

    const [[month, direction], setPage] = useState([new Date(), 0]);

    const paginate = async (newDirection: number) => {
        month.setMonth(month.getMonth() + newDirection)
        await animationControl.start({
            zIndex: 0,
            x: newDirection < 0 ? 300 : -300,
            opacity: 0
        })
        setPage([month, newDirection]);
    };

    useEffect(() => {
        animationControl.start({
            zIndex: 1,
            x: 0,
            opacity: 1
        })
    })

    const swipeConfidenceThreshold = 10000;
    const swipePower = (offset: number, velocity: number) => {
        return Math.abs(offset) * velocity;
    };

    const monthTitle = MONTHS[month.getMonth()] + " " + month.getFullYear()

    return (
        <CalendarFrame exit={{opacity: 0}}>
            <ArrowLeft onClick={() => selectedWorkout == null && paginate(-1)}><FontAwesomeIcon icon={faChevronLeft} /></ArrowLeft>
            <motion.div className="swipe"
                key={month.toString()}
                custom={direction}
                initial={{
                    x: direction > 0 ? 100 : -100,
                    opacity: 0
                }}
                animate={animationControl}
                transition={{
                    x: {type: "spring", stiffness: 300, damping: 30},
                    opacity: {duration: 0.2}
                }}
                drag={selectedWorkout == null ? "x" : false}
                dragConstraints={{left: 0, right: 0}}
                dragElastic={1}
                onDragEnd={async (e, {offset, velocity}) => {
                    const swipe = swipePower(offset.x, velocity.x);

                    if (swipe < -swipeConfidenceThreshold) {
                        await paginate(1);
                    } else if (swipe > swipeConfidenceThreshold) {
                        await paginate(-1);
                    }
                }}
            >
                <Card title={monthTitle} onClose={() => navigate(AppRoutes.main(search))}>
                    <AnimatePresence exitBeforeEnter>
                        {selectedWorkout == null &&
                          <motion.div key="calendar" exit={{opacity: 0}} style={{height: '100%',  width: '100%'}}>
                            <Calendar
                              month={month.getMonth()}
                              year={month.getFullYear()}
                              data={service.getMonthStatistics(month.getFullYear(), month.getMonth())}
                              onSelect={(gender, workout) => setSelectedWorkout({gender: gender, workout: workout})}
                            />
                          </motion.div>
                        }
                        {selectedWorkout != null &&
                          <WorkoutInfo key="workout" gender={selectedWorkout.gender} workout={selectedWorkout.workout} onBack={() => setSelectedWorkout(null)}/>
                        }
                    </AnimatePresence>
                </Card>
            </motion.div>
            <ArrowRight onClick={() => selectedWorkout == null &&  paginate(1)}><FontAwesomeIcon icon={faChevronRight} /></ArrowRight>
        </CalendarFrame>
    )
}

export default CalendarScreen;