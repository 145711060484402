const AppSettings = {
    "stat.gbbt.ru:": {
        apiUrl: "https://gymboss.gbbt.ru"
    },
    "stat.ptbt.site:": {
        apiUrl: "https://gymbossbot.ptbt.site"
    },
    "stat.ptbt.ru:": {
        apiUrl: "https://gymbossbot.ptbt.ru"
    }
}

export default AppSettings