export default class AppRoutes{
    static main = function(search) {
        return '/' + search;
    }
    static calendar = function(search) {
        return '/calendar' + search;
    }
    static exercises = function(search) {
        return '/exercises' + search;
    }
}