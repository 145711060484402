import styled from "styled-components";
import {motion} from "framer-motion";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AppRoutes from "../../routes";
import StatisticsService from "../../service/StatisticsService";
import ErrorDescriptionDTO from "../../service/dto/ErrorDescriptionDTO";
import RatingDTO from "../../service/dto/RatingDTO";
import {Sparklines, SparklinesCurve} from 'react-sparklines';
import Loader from "../../components/loader/Loader";
import Error from "../../components/error/Error";

const Button = styled(motion.div)`
  border-radius: 5px;
  background: #0edba4;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;

  :first-child {
    margin-right: 10px;
  }

  cursor: pointer;
  text-align: center;
  vertical-align: baseline;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
`

const MainFrame = styled(motion.div)`
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  height: 25%;
  width: 100%;
  max-width: 300px;
`

const Rating = styled(motion.div)`
  height: 75%;
  width: 100%;
  max-width: 300px;
  background-color: #0edba4;
  margin: 20px;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  flex-direction: column;

  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);

  color: white;

  .value {
    font-size: 3em;
    font-family: "Aldrich";
  }

  .title {
    font-size: 1em;
  }

  .ratingData {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ratingDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;

    > div {
      flex: 50%;
      margin-bottom: 5px
      
      > img {
        margin-right: 5px;
        height: 25px;
      }
    }

    .muscle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;

      font-family: "Aldrich";
    }
  }


`

function MainScreen({service}: { service: StatisticsService }) {

    const navigate = useNavigate();
    const { search } = useLocation();
    const [rating, setRating] = useState<RatingDTO>(null)
    const [error, setError] = useState<ErrorDescriptionDTO>(null)

    const frameAnimations = {
        initial: {opacity: 0},
        visible: {opacity: 1, transition: {when: "beforeChildren", staggerChildren: 0.1}},
        hidden: {opacity: 0}
    }

    const buttonsAnimations = {
        initial: {x: -10, opacity: 0},
        visible: {x: 0, opacity: 1},
        hidden: {x: 0, opacity: 0}
    }

    const ratingAnimations = {
        initial: {opacity: 0, scale: 0.8},
        visible: {opacity: 1, scale: 1},
        hidden: {opacity: 0}
    }

    useEffect(() => {
        if (rating == null && error == null) {
            service.getRating()
                .then((r) => setRating(r))
                .catch(e => setError(e as ErrorDescriptionDTO))
        }
    })

    return (
        <MainFrame
            variants={frameAnimations}
            initial="initial"
            animate="visible"
            exit="hidden"
        >
            {rating === null && error === null && (
                <Loader/>
            )}
            {error !== null && (
                <Error error={error.status}/>
            )}
            {rating !== null && (
                <>
                    <Rating variants={ratingAnimations}>
                        <div className={"ratingData"}>
                            <div className={"value"}>{rating.total}</div>
                            <div className={"title"}>Ваш рейтинг</div>

                            <div className="ratingDetails">
                                <div className={"muscle"}>
                                    <img src={"/images/chest.png"}/>
                                    <span>{rating.chest}</span>
                                </div>
                                <div className={"muscle"}>
                                    <img src={"/images/shoulders.png"}/>
                                    <span>{rating.shoulders}</span>
                                </div>
                                <div className={"muscle"}>
                                    <img src={"/images/biceps.png"}/>
                                    <span>{rating.biceps}</span>
                                </div>
                                <div className={"muscle"}>
                                    <img src={"/images/triceps.png"}/>
                                    <span>{rating.triceps}</span>
                                </div>
                                <div className={"muscle"}>
                                    <img src={"/images/core.png"}/>
                                    <span>{rating.core}</span>
                                </div>
                                <div className={"muscle"}>
                                    <img src={"/images/rear.png"}/>
                                    <span>{rating.rear}</span>
                                </div>
                                <div className={"muscle"}>
                                    <img src={"/images/legs.png"}/>
                                    <span>{rating.legs}</span>
                                </div>
                                <div className={"muscle"}>&nbsp;
                                </div>
                            </div>
                        </div>
                        <Sparklines data={rating.history}>
                            <SparklinesCurve color="white"/>
                        </Sparklines>
                    </Rating>

                    <Buttons>
                        <Button variants={buttonsAnimations} onClick={() => navigate(AppRoutes.calendar(search))}
                                whileTap={{scale: 0.9}}>
                            <img src={"/images/calendar.png"} width={70}/>
                            <div className={"title"}>Календарь</div>
                        </Button>
                        <Button variants={buttonsAnimations} onClick={() => navigate(AppRoutes.exercises(search))}
                                whileTap={{scale: 0.9}}>
                            <img src={"/images/fitball.png"} width={70}/>
                            <div className={"title"}>Упражнения</div>
                        </Button>
                    </Buttons>
                </>
            )}
        </MainFrame>
    );
}

export default MainScreen;