import styled from "styled-components";
import MonthStatisticsDTO, {WorkoutDataDTO} from "../../service/dto/MonthStatisticsDTO";
import {useEffect, useState} from "react";
import _ from 'lodash'
import {motion} from "framer-motion";
import ErrorDescriptionDTO from "../../service/dto/ErrorDescriptionDTO";
import Error from '../error/Error'
import GenderDTO from "../../service/dto/GenderDTO";

const CalendarContent = styled.div`
  display: table;
  height: 100%;
  width: 100%;
  border-radius: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`

const CalendarRow = styled.div`
  display: table-row;
`

const CalendarHeader = styled.div`
  display: table-cell;
  color: white;
  font-weight: bold;
  background-color: #0edba4;
  height: 1em;
  padding: 3px;
  text-align: center;
  width: 14.28%;
`

const CalendarCell = styled(motion.div)`
  display: table-cell;
  padding: 3px;
  text-align: right;
  width: 14.28%;
  border-right: 1px solid #0edba4;
  border-bottom: 1px solid #0edba4;

  :last-child {
    border-right: none !important;
  }

  &.today {
    background: rgba(14, 219, 164, 0.3);
  }

  &.workout {
    cursor: pointer;
    background-image: url("/images/muscle.png");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .date1:after {
    content: "1";
  }

  .date2:after {
    content: "2";
  }

  .date3:after {
    content: "3";
  }

  .date4:after {
    content: "4";
  }

  .date5:after {
    content: "5";
  }

  .date6:after {
    content: "6";
  }

  .date7:after {
    content: "7";
  }

  .date8:after {
    content: "8";
  }

  .date9:after {
    content: "9";
  }

  .date10:after {
    content: "10";
  }

  .date11:after {
    content: "11";
  }

  .date12:after {
    content: "12";
  }

  .date13:after {
    content: "13";
  }

  .date14:after {
    content: "14";
  }

  .date15:after {
    content: "15";
  }

  .date16:after {
    content: "16";
  }

  .date17:after {
    content: "17";
  }

  .date18:after {
    content: "18";
  }

  .date19:after {
    content: "19";
  }

  .date20:after {
    content: "20";
  }

  .date21:after {
    content: "21";
  }

  .date22:after {
    content: "22";
  }

  .date23:after {
    content: "23";
  }

  .date24:after {
    content: "24";
  }

  .date25:after {
    content: "25";
  }

  .date26:after {
    content: "26";
  }

  .date27:after {
    content: "27";
  }

  .date28:after {
    content: "28";
  }

  .date29:after {
    content: "29";
  }

  .date30:after {
    content: "30";
  }

  .date31:after {
    content: "31";
  }
`

const DAY_LABELS = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

const NOW = new Date();

interface DayData {
    date: Date,
    workout: WorkoutDataDTO | null
}

const isSameDay = function(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
}

const getDay = function (date: Date | null): number {
    if (date === null)
        return 0;

    let day = date.getDay();
    if (day === 0) day = 7;
    return day - 1;
};

const isToday = function (dayData: DayData | null): boolean {
    if (dayData === null)
        return false;

    return isSameDay(dayData.date, NOW)
}

function Calendar(
    {year, month, data, onSelect}:
        { year: number, month: number, data: Promise<MonthStatisticsDTO>, onSelect: (gender: GenderDTO, workout:WorkoutDataDTO) => void}
) {

    const [stat, setStat] = useState<MonthStatisticsDTO>(null)
    const [error, setError] = useState<ErrorDescriptionDTO>(null);

    useEffect(() => {
        if (stat === null && error === null) {
            data
                .then(v => setStat(v))
                .catch(e => setError(e as ErrorDescriptionDTO))
        }
    })

    const rows: (DayData | null)[][] = []

    let row: (DayData | null)[] = [null, null, null, null, null, null, null]
    let d = new Date(year, month);
    while (d.getMonth() === month) {
        const day = getDay(d)

        row[day] = {
            date: new Date(d),
            workout: stat == null ? null : _.find(stat.workouts, (w) => isSameDay(w.date, d)) as WorkoutDataDTO
        }

        if (day % 7 === 6) {
            rows.push(row)
            row = [null, null, null, null, null, null, null]
        }

        d.setDate(d.getDate() + 1);
    }
    rows.push(row)

    const getCellClasses = function(dayData: DayData | null): string {
        if (dayData == null)
            return "";

        let result = ""
        if (isToday(dayData))
            result += "today"
        if (dayData.workout != null)
            result += " workout"

        return result;
    }

    const cKey = "cal-" + year + month;
    return (
        <CalendarContent key={cKey}>
            <CalendarRow key={cKey + "-title"}>
                {DAY_LABELS.map(l => (<CalendarHeader key={cKey + "-header-" + l}>{l}</CalendarHeader>))}
            </CalendarRow>

            {error !== null && (
                <Error error={error.status}/>
            )}

            {error === null && rows.map((row, rowIdx) =>
                <CalendarRow key={cKey + "-row-" + rowIdx}>
                    {
                        row.map((dayData, callIdx) => (
                            <CalendarCell
                                key={cKey + "-row-" + rowIdx + "-cell-" + callIdx}
                                className={getCellClasses(dayData)}
                                onClick={() => dayData.workout != null ? onSelect(stat.gender, dayData.workout) : false}
                            >
                                <div className={"date" + (dayData === null ? "" : dayData.date.getDate())}></div>
                                <div>&nbsp;</div>
                            </CalendarCell>
                        ))
                    }
                </CalendarRow>
            )}
        </CalendarContent>
    )

}

export default Calendar;