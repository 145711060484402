import MonthStatisticsDTO from "./dto/MonthStatisticsDTO";
import ExerciseSlotDTO from "./dto/ExerciseSlotDTO";
import ExerciseDTO from "./dto/ExerciseDTO";
import ExerciseSetDTO from "./dto/ExerciseSetDTO";
import ErrorDescriptionDTO from "./dto/ErrorDescriptionDTO";
import ExerciseStatisticsDTO from "./dto/ExerciseStatisticsDTO";
import RatingDTO from "./dto/RatingDTO";

export default interface StatisticsService {
    getRating(): Promise<RatingDTO>

    getMonthStatistics(year: number, month: number): Promise<MonthStatisticsDTO>

    getExercisesData(): Promise<ExerciseStatisticsDTO>
}

export class StatisticsServiceImpl implements StatisticsService {

    private apiUrl: string
    private hash: string

    private readonly calendarDataCache: any;
    private readonly ratingCache: any;
    private readonly exerciseDataCache: any;

    constructor(apiUrl: string, hash: string) {
        this.apiUrl = apiUrl;
        this.hash = hash;
        this.calendarDataCache = {}
        this.exerciseDataCache = {}
        this.ratingCache = {}
    }

    getMonthStatistics(year: number, month: number): Promise<MonthStatisticsDTO> {
        const cachedData = this.calendarDataCache[year + '_' + month]

        if (cachedData !== undefined)
            return Promise.resolve(cachedData)

        return fetch(this.apiUrl + '/stat/' + this.hash + '/month/' + year + "/" + month)
            .catch(e => {throw {status: e.message} as ErrorDescriptionDTO})
            .then(StatisticsServiceImpl._checkResponse)
            .then((response) => response.json() as MonthStatisticsDTO)
            .then((stat: MonthStatisticsDTO) => {
                stat.workouts.forEach(w => w.date = new Date(w.date));
                return stat;
            })
            .then((stat: MonthStatisticsDTO) => {
                this.calendarDataCache[year + '_' + month] = stat
                return stat
            })
    }

    getExercisesData(): Promise<ExerciseStatisticsDTO> {
        const cachedData = this.exerciseDataCache['data']

        if (cachedData !== undefined)
            return Promise.resolve(cachedData)

        return fetch(this.apiUrl + '/stat/' + this.hash + '/exercises')
            .catch(e => {throw {status: e.message} as ErrorDescriptionDTO})
            .then(StatisticsServiceImpl._checkResponse)
            .then((response) => response.json() as ExerciseStatisticsDTO)
            .then((data: ExerciseStatisticsDTO) => {
                data.exercises.forEach(ex => ex.results.forEach(r => r.date = new Date(r.date)))
                return data
            })
            .then((data: ExerciseStatisticsDTO) => {
                this.exerciseDataCache['data'] = data
                return data
            })
    }

    getRating(): Promise<RatingDTO> {
        const cachedData = this.ratingCache['data']

        if (cachedData !== undefined)
            return Promise.resolve(cachedData)

        return fetch(this.apiUrl + '/stat/' + this.hash + '/rating')
            .catch(e => {throw {status: e.message} as ErrorDescriptionDTO})
            .then(StatisticsServiceImpl._checkResponse)
            .then((response) => response.json() as RatingDTO)
            .then((rating: RatingDTO) => {
                this.ratingCache['data'] = rating
                return rating
            })
    }

    static _checkResponse(response) {
        if (response.status === 200)
            return response;

        return response.json().then(json => {
            throw {status: json.status} as ErrorDescriptionDTO
        });
    }

}

export class StatisticsServiceStub implements StatisticsService {

    private calendarData = {
        '20224': {
            workouts: [
                {
                    date: new Date(2022, 3, 1),
                    slots: [
                        {
                            gender: "MAN",
                            exercise: {
                                id: "BARBELL_BENCH_PRESS",
                                name: "Жим штанги лежа"
                            } as ExerciseDTO,
                            sets: [
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO
                            ]
                        } as ExerciseSlotDTO,
                        {
                            gender: "MAN",
                            exercise: {
                                id: "BARBELL_BENCH_PRESS",
                                name: "Жим штанги лежа"
                            } as ExerciseDTO,
                            sets: [
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO
                            ]
                        } as ExerciseSlotDTO,
                        {
                            gender: "MAN",
                            exercise: {
                                id: "BARBELL_BENCH_PRESS",
                                name: "Жим штанги на наклонной скамье"
                            } as ExerciseDTO,
                            sets: [
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO
                            ]
                        } as ExerciseSlotDTO,
                        {
                            gender: "MAN",
                            exercise: {
                                id: "BARBELL_BENCH_PRESS",
                                name: "Жим штанги лежа"
                            } as ExerciseDTO,
                            sets: [
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO
                            ]
                        } as ExerciseSlotDTO,
                        {
                            gender: "MAN",
                            exercise: {
                                id: "DUMBELL_ROWS",
                                name: "Тяга гантели"
                            } as ExerciseDTO,
                            sets: [
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO
                            ]
                        } as ExerciseSlotDTO,
                        {
                            gender: "MAN",
                            exercise: {
                                id: "ARNOLD_DUMBELL_PRESS",
                                name: "Жим Арнольда"
                            } as ExerciseDTO,
                            sets: [
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO,
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO,
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO,
                                {
                                    value: 50,
                                    target: 10,
                                    performed: 9
                                } as ExerciseSetDTO
                            ]
                        } as ExerciseSlotDTO
                    ]
                }
            ]
        } as MonthStatisticsDTO,
        '20225': {
            workouts: [
                {
                    date: new Date(2022, 4, 1),
                    slots: []
                }
            ]
        } as MonthStatisticsDTO,
        'default': {
            workouts: []
        } as MonthStatisticsDTO
    }

    async getRating(): Promise<RatingDTO> {
        return new Promise<RatingDTO>((resolve, reject) => {
            setTimeout(() => resolve({
                total: 655,
                chest: 120,
                shoulders: 98,
                biceps: 95,
                triceps: 99,
                core: 155,
                rear: 213,
                legs: 255,

                history: [100, 70, 120, 190, 215]
            }), 1000)
        });
    }

    async getMonthStatistics(year: number, month: number): Promise<MonthStatisticsDTO> {
        return new Promise<MonthStatisticsDTO>((resolve, reject) => {
            let data = this.calendarData['' + year + (month + 1)]
            if (!data)
                data = this.calendarData.default
            setTimeout(() => resolve(data), 500)
        })
    }

    async getExercisesData(): Promise<ExerciseStatisticsDTO> {
        return new Promise<ExerciseStatisticsDTO>((resolve, reject) => {
            setTimeout(() => resolve({
                gender: "MAN",
                exercises: [
                    {
                        exercise: {
                            id: "ARNOLD_DUMBELL_PRESS",
                            name: "Жим Арнольда"
                        } as ExerciseDTO,
                        results: [
                            {
                                date: new Date(2022, 4, 1),
                                value: 70,
                                valueStr: "70кг на 10раз"
                            },
                            {
                                date: new Date(2022, 4, 1),
                                value: 80,
                                valueStr: "70кг на 10раз"
                            }
                        ]
                    },
                    {
                        exercise: {
                            id: "DUMBELL_ROWS",
                            name: "Тяга гантели"
                        } as ExerciseDTO,
                        results: [
                            {
                                date: new Date(2022, 4, 1),
                                value: 70,
                                valueStr: "70кг на 10раз"
                            },
                            {
                                date: new Date(2022, 4, 1),
                                value: 80,
                                valueStr: "70кг на 10раз"
                            }
                        ]
                    },
                    {
                        exercise: {
                            id: "BARBELL_BENCH_PRESS",
                            name: "Жим штанги лежа"
                        } as ExerciseDTO,
                        results: [
                            {
                                date: new Date(2022, 4, 1),
                                value: 70,
                                valueStr: "70кг на 10раз"
                            },
                            {
                                date: new Date(2022, 4, 1),
                                value: 80,
                                valueStr: "70кг на 10раз"
                            }
                        ]
                    }
                ]

            }), 1000)
        })
    }
}