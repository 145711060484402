import React from 'react';
import './App.css';
import {AnimatePresence} from "framer-motion";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import MainScreen from "./screens/main/MainScreen";
import CalendarScreen from "./screens/calendar/CalendarScreen";
import StatisticsService, {StatisticsServiceImpl, StatisticsServiceStub} from "./service/StatisticsService";
import AppRoutes from "./routes";
import ExercisesScreen from "./screens/exercises/ExercisesScreen";
import AppSettings from "./AppSettings";

function getUrlParameterByName(name, defaultValue) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return defaultValue;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const settings = AppSettings[window.location.hostname + ":" + window.location.port]
const hash = getUrlParameterByName("c", null)

// @ts-ignore
const tgWebApp = window.Telegram.WebApp;
tgWebApp.ready();
tgWebApp.expand();

const service: StatisticsService = hash === 'TEST' ? new StatisticsServiceStub() : new StatisticsServiceImpl(settings.apiUrl, hash)

function App() {

    return (
        <Router>
            <Routes>
                <Route path={AppRoutes.main("")} element={
                    <AnimatePresence exitBeforeEnter>
                        <MainScreen key="mainScreen" service={service}/>
                    </AnimatePresence>
                }/>
                <Route path={AppRoutes.calendar("")} element={
                    <AnimatePresence exitBeforeEnter>
                        <CalendarScreen key="calendarScreen" service={service}/>
                    </AnimatePresence>
                }/>
                <Route path={AppRoutes.exercises("")} element={
                    <AnimatePresence exitBeforeEnter>
                        <ExercisesScreen key="exercisesScreen" service={service}/>
                    </AnimatePresence>
                }/>
            </Routes>
        </Router>
    )
}

export default App;
