import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {motion} from "framer-motion";

const CardContent = styled.div`
  border-radius: 30px;
  background: white;
  border: #0edba4 solid 3px;
  //width: 100%;
  height: 100%;
  vertical-align: baseline;
  position: relative;
  display: flex;
  flex-direction: column;
  
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
`

const CardTitle = styled.div`
  //width: 100%;
  padding-top: 10px;
  height: 40px;
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  text-align: center;
  font-weight: bold;
  color: #80828d;
`

const CardTitleButton = styled(motion.label)`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: #80828d;
`

const CardBody = styled.div`
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

const CardFooter = styled.div`
  //width: 100%;
  background: white;
  height: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`

function Card({title, onClose, children}) {
    return (
        <CardContent>
            <CardTitle>{title}</CardTitle>
            <CardTitleButton onClick={onClose} whileTap={{ scale: 0.9 }}>
                <FontAwesomeIcon icon={faXmark} fontSize={30} width={20} height={20}/>
            </CardTitleButton>
            <CardBody>
            {children}
            </CardBody>
            <CardFooter/>
        </CardContent>
    )
}

export default Card;