import StatisticsService from "../../service/StatisticsService";
import styled from "styled-components";
import {motion, useAnimation} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import ExerciseStatisticsDTO from "../../service/dto/ExerciseStatisticsDTO";
import {useLocation, useNavigate} from "react-router-dom";
import {Sparklines, SparklinesLine} from 'react-sparklines';
import _ from 'lodash';
import Loader from "../../components/loader/Loader";
import Error from "../../components/error/Error";
import ErrorDescriptionDTO from "../../service/dto/ErrorDescriptionDTO";
import AppRoutes from "../../routes";

const ExercisesFrame = styled(motion.div)`
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`

const Title = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
  padding: 0;
  margin: 0;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  border: 3px solid #0edba4;
  border-radius: 5px;

  .close {
    display: flex;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    width: 20px;
    justify-content: center;
    font-size: 2em;
    cursor: pointer;
    color: #80828d;
  }
`

const List = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  max-width: 450px;
  height: 100%;
  padding-bottom: 10px;
  
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

const Pepper = styled(motion.div)`
  display: flex;
  border-radius: 20px;

  margin: 10px 5px 0 5px;

  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
`

const Search = styled(motion.form)`
  width: 100%;
  position: relative;
  display: flex;

  .searchTerm {
    width: 100%;
    padding: 5px;
    height: 2.3em;
    font-size: 1em;
    border-radius: 0;
    border: 0;
    background: white;
    outline: none;
  }
`

const Exercise = styled(motion.div)`
  width: 100%;
  
  display: flex;
  flex-direction: column;
  
  cursor: pointer;
`

const ExerciseCardContent = styled(motion.div)`
  margin: 10px;
  display: flex;
  flex-direction: row;
`

const ExerciseImage = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 10px;
`

const ExerciseDetails = styled.div`
  width: 100%;
  max-width: 500px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  
  .title {
    font-weight: bold;
    padding-bottom: 5px;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    @media screen and (max-width: 400px) {
      max-width: 180px;
    }
    
    @media screen and (max-width: 420px) {
      max-width: 200px;
    }
  }
  .lastValue {
    flex: 1;
  }
  .lastValueTitle {
    flex: 1;
    font-size: 0.6em;
  }
`

const ExerciseChart = styled.div`
`

const ExerciseResults = styled.div`
  display: table;
  padding-left: 10px;
  padding-right: 10px;
  
  border-width: 0;
  
  .row {
    display: table-row;
  }
  
  .row:first-child .cell {
    border-top: 1px dotted #80828d;
  }
  
  .cell {
    display: table-cell;
    border-left: 1px dotted #80828d;
    border-bottom: 1px dotted #80828d;
  }
  
  .cell:last-child {
    border-right: 1px dotted #80828d;
  }
`

export default function ExercisesScreen({service}: { service: StatisticsService }) {

    const navigate = useNavigate();
    const { search } = useLocation();
    const pepperAnimations = useAnimation();
    const inputRef = useRef<HTMLInputElement>()

    const exerciseAnimations = {
        closed: {
            height: "140px"
        },
        opened: {
            height: "15em"
        }
    }

    const [data, setData] = useState<ExerciseStatisticsDTO>(null)
    const [error, setError] = useState<ErrorDescriptionDTO>(null)
    const [selected, setSelected] = useState(null)
    const [filter, setFilter] = useState("")

    useEffect(() => {
        if (data !== null || error !== null)
            return;

        service.getExercisesData()
            .then(data => setData(data))
            .catch(error => setError(error))
    })

    useEffect(() => {
        if (data === null && error == null)
            return;

        pepperAnimations.start(i => ({
            opacity: 1, y: 0,
            transition: {delay: i*0.1}
        }))
    })

    return (
        <ExercisesFrame key="exScr" exit={{opacity: 0}}>
            {data === null && error == null && (<Loader/>)}
            {error !== null && (<Error error={error.status}/>)}
            {data !== null && (
                <>
                    <Title custom={0} initial={{opacity: 0, y: 100}} animate={pepperAnimations} key={"search"}>
                        <Search  action="."  onSubmit={(e) => {e.preventDefault(); setFilter(inputRef.current.value)}}>
                            <input ref={inputRef} type="search" className="searchTerm" placeholder="Название упражнения" onChange={() => setFilter(inputRef.current.value)}/>
                        </Search>
                        <div className="close" >
                            <FontAwesomeIcon icon={faXmark} onClick={() => navigate(AppRoutes.main(search))}/>
                        </div>
                    </Title>
                    <List>
                        {data != null && data.exercises.filter(ex => ex.exercise.name.includes(filter)).map((ex, idx) => (
                            <Pepper custom={idx+1} initial={{opacity: 0, y: 100}} animate={pepperAnimations} key={ex.exercise.id}>
                                <Exercise
                                    variants={exerciseAnimations}
                                    animate={selected === ex.exercise.id ? "opened" : "closed"}
                                    onClick={() => setSelected(selected === ex.exercise.id ? null : ex.exercise.id)}
                                >
                                    <ExerciseCardContent key={ex.exercise.name} >
                                        <ExerciseImage  src={"/images/exercises/" + data.gender.toLowerCase() + "/" + ex.exercise.id.toLowerCase() + ".png"}/>
                                        <ExerciseDetails>
                                            <div className="title">{ex.exercise.name}</div>
                                            <div className="lastValue">{ex.results[ex.results.length - 1].valueStr}</div>
                                            <div className="lastValueTitle">Последний результат</div>
                                            <ExerciseChart>
                                                <Sparklines data={_.map(ex.results, r => r.value)}>
                                                    <SparklinesLine color="#0edba4" />
                                                </Sparklines>
                                            </ExerciseChart>
                                        </ExerciseDetails>
                                    </ExerciseCardContent>
                                    {selected === ex.exercise.id && (
                                        <ExerciseResults>
                                            {ex.results.map(res => (
                                                <div className="row" key={"res" + ex.exercise.id + res.date}>
                                                    <div className="cell">{res.date.toLocaleDateString("ru-RU")}</div>
                                                    <div className="cell">{res.valueStr}</div>
                                                </div>
                                            ))}
                                        </ExerciseResults>
                                    )}
                                </Exercise>
                            </Pepper>
                        ))}
                    </List>
                </>
            )}
        </ExercisesFrame>
    )
}