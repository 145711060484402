import styled from "styled-components";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {WorkoutDataDTO} from "../../service/dto/MonthStatisticsDTO";
import {motion} from "framer-motion";
import GenderDTO from "../../service/dto/GenderDTO";
import ExerciseSetDTO from "../../service/dto/ExerciseSetDTO";

const WorkoutTitle = styled(motion.div)`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
  background: #0edba4;
  cursor: pointer;
  color: white;
  :hover {
    background: #0edba4;
    cursor: pointer
  }
`

const WorkoutCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

const WorkoutCards = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  max-width: 700px;
`

const ExerciseCardContent = styled(motion.div)`
  margin: 10px;
  display: flex;
  flex-direction: row;
`

const ExerciseImage = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 10px;
`

const ExerciseDetails = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  .title {
    max-width: 200px;
    font-weight: bold;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 400px) {
      max-width: 180px;
    }

    @media screen and (max-width: 420px) {
      max-width: 200px;
    }
  }
`

const NoInfo = styled(motion.div)`
  text-align: center;
  padding-top: 50px;
`

export default function WorkoutInfo({gender, workout, onBack}: {gender: GenderDTO, workout: WorkoutDataDTO, onBack: () => void}) {
    const dateStr = workout.date.toLocaleDateString("ru-RU")

    const frameAnimations = {
        initial: {opacity: 0},
        visible: {opacity: 1, transition: {when: "beforeChildren", staggerChildren: 0.1}},
        hidden: {opacity: 0, transition: {when: "afterChildren", staggerChildren: 0.1, staggerDirection: -1}}
    }

    const buttonsAnimations = {
        initial: {x: -10, opacity: 0},
        visible: {x: 0, opacity: 1},
        hidden: {x: -10, opacity: 0}
    }

    const hasValue = (set: ExerciseSetDTO) => set.value !== null && set.value > 0
    const hasPerformed = (set: ExerciseSetDTO) => set.performed !== null

    return (
        <motion.div variants={frameAnimations} initial="initial" animate="visible" exit="hidden">
            <WorkoutTitle variants={buttonsAnimations} onClick={onBack}>
                <FontAwesomeIcon icon={faArrowLeft} style={{marginRight:20}}/>{dateStr}
            </WorkoutTitle>
            <WorkoutCardsWrapper>
                <WorkoutCards>
                    {workout.slots.map((slot) => (
                        <ExerciseCardContent key={slot.exercise.name} variants={buttonsAnimations}>
                            <ExerciseImage  src={"/images/exercises/" + gender.toLowerCase() + "/" + slot.exercise.id.toLowerCase() + ".png"}/>
                            <ExerciseDetails>
                                <div className="title">{slot.exercise.name}</div>
                                {slot.sets.map((set, idx) => (
                                    <div key={slot.exercise.id + idx}>{hasValue(set) ? set.value + " " + set.valueMeasure : ""}{hasValue(set) && hasPerformed(set)? ", " : ""}{ hasPerformed(set)? set.performed + " " + set.targetMeasure : ""}</div>
                                ))}
                            </ExerciseDetails>
                        </ExerciseCardContent>
                    ))}
                    {workout.slots.length === 0 && <NoInfo variants={buttonsAnimations}>Нет информации</NoInfo>}
                </WorkoutCards>
            </WorkoutCardsWrapper>
        </motion.div>

    )
}